<template>
  <div>
    <b-col>
      <b-card
        :title="name"
        :img-src="imgLink"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 20rem;"
        class="mb-2"
      >
        <b-card-text>
          <p style="font-weight: bold;">{{ date }}</p>
          {{ text }}
        </b-card-text>

        <div>
<!--          <p v-if="slug !== ''" style="font-weight: bold;">Ilość zarejestrowanych osób: {{ registrationCount }}</p>-->

          <b-button
            class="main_button_with_margin"
            v-if="fbLink !== '' &&  roundsBoardLink === null"
            :href="fbLink">
            Facebook
          </b-button>

          <b-button
            class="main_button_with_margin"
            v-if="roundsBoardLink !== null"
            :href="roundsBoardLink">
            RoundsBoard
          </b-button>

          <b-button
            class="main_button"
            v-if="registrationLink !== null && (egdLink === null || egdLink === '')"
            :href="registrationLink"
          >
            Rejestracja
          </b-button>

          <b-button
            class="main_button"
            v-if="(egdLink !== null && egdLink !== '')"
            :disabled="isDisabledBtnEgdLink"
            :href="egdLink"
          >
            Wyniki
          </b-button>
        </div>
      </b-card>
    </b-col>
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/mainStyle.css";
</style>

<script>
export default {
  props: [
    "name",
    "date",
    "id",
    "imgLink",
    "text",
    "roundsBoardLink",
    "fbLink",
    "egdLink",
    "registrationLink",
    "slug",
    "registrationCount"
  ],

  computed: {
    modalId() {
      return `modal-${this.id}`;
    },

    isDisabledBtnEgdLink: function() {
      return this.egdLink === "";
    }
  }
};
</script>
